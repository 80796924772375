<template>
    <div id="page-loyalty-management-customer-search">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="back_to">Back to Search Voucher</router-link>

                    <h1 class="heading">Customer Search</h1>

                    <app-error v-model="errors.show" :message="errors.message"></app-error>

                    <div class="search-box">
                        <app-autocomplete
                            label="Search customers"

                            :options="customers"

                            :delay="500"

                            @typing="searchCustomers"
                            @select="selectCustomer"
                        />
                    </div>

                    <app-table
                        v-if="have_prepay_services"

                        class="prepay-services-table"

                        title="Prepay services"

                        :cols="cols"
                        :rows="rows"

                        @select-service="onSelectService"
                    />
                </div>
            </div>
        </div>

        <app-dialog-select-service
            v-if="dialogs.service.select.show"
            v-model="dialogs.service.select.show"

            @confirm="onDialogSelectServiceConfirm"
            @close="onDialogSelectServiceClose"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appAutocomplete from '@/components/app-autocomplete'

import appDialogSelectService from './components/app-dialog-select-service'

import errMessage from '@/helpers/errMessage'

import testPrepayServices from './test-prepay-services.json'

export default {
    components: {
        appLoader,
        appError,
        appTable,
        appAutocomplete,

        appDialogSelectService,
    },

    data() {
        return {
            customers: [],

            customer_uuid: null,

            prepay_services: [],

            cols: [
                { key: 'ServiceName', title: 'Service name', highlight: true, },
                { key: 'Description', title: 'Description',                   },
                { key: 'Value',       title: 'Value',                         },

                { key: 'select', action: 'select-service', icon: 'select', text: 'Select', behavior: 'detached', },
            ],

            loading: false,

            dialogs: {
                service: {
                    select: {
                        show: false,
                    },
                },
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    methods: {
        searchCustomers(search_query) {
            const payload = {
                params: {
                    SmartSearch: search_query,
                    'SearchOptions.PageSize': 10,
                },
            }

            this.$store.dispatch('searchCustomers', payload)
                .then(customers => {
                    this.customers = customers.map(customer => {
                        let title = customer.UUID

                        if (customer.FirstName && customer.LastName) {
                            title = `${customer.FirstName} ${customer.LastName}`
                        } else if (customer.Email) {
                            title = customer.Email
                        }

                        return {
                            key: customer.UUID,
                            value: title,
                        }
                    })
                })
                .catch(error => {
                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        selectCustomer({ key }) {
            this.customer_uuid = key

            this.getPrepayServices()
        },

        getPrepayServices() {
            this.loading = true

            const delay_in_ms = 1000

            setTimeout(() => {
                this.prepay_services = testPrepayServices

                this.loading = false
            }, delay_in_ms)
        },

        onSelectService() {
            this.dialogs.service.select.show = true
        },

        onDialogSelectServiceConfirm() {
            this.dialogs.service.select.show = false

            this.$router.push({ ...this.back_to })
        },

        onDialogSelectServiceClose() {
            this.dialogs.service.select.show = false
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        have_prepay_services() {
            return this.prepay_services.length > 0
        },

        rows() {
            const rows = []

            for (let i = 0, len = this.prepay_services.length; i < len; i++) {
                const service = this.prepay_services[i]

                rows.push({
                    uuid: service.UUID,

                    ServiceName: service.ServiceName ? service.ServiceName : '?',
                    Description: service.Description ? service.Description : '?',
                    Value: `$${ service.Cents % 100 == 0 ? service.Cents / 100 : (service.Cents / 100).toFixed(2) }`,
                })
            }

            return rows
        },

        back_to() {
            return { name: 'loyalty-management-search-voucher' }
        },
    },
}
</script>

<style lang="scss">
#page-loyalty-management-customer-search {
    margin-top: 24px;
    padding-bottom: 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .search-box {
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-secondary;
        box-shadow: var(--box-shadow-primary);
    }

    .prepay-services-table {
        margin-top: 30px;

        @include table-cols-width((200px, 650px, 100px, 100px), true, 1);
    }
}

@media (max-width: $tablet-size) {
    #page-loyalty-management-customer-search {
        .prepay-services-table {
            @include table-cols-width((170px, 560px, 80px, 100px), true, 1);
        }
    }
}

@media (max-width: $mobile-size) {
    #page-loyalty-management-customer-search {
        padding-bottom: 64px;

        .btn-back-to {
            margin-bottom: 8px;
        }

        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .search-box {
            padding: 16px 8px;
        }

        .prepay-services-table {
            margin-top: 15px;

            @include table-cols-width-mobile((80px, 170px, 24px), true);
        }
    }
}
</style>